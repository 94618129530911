<template>
  <div class="container">
    <div class="handle-box">
      <el-input v-model="query.lessonName" placeholder="Lesson Name" class="handle-input mr10" :clearable="true"
        :input-style="inMiniScreen ? 'width:60%' : ''" @input="handleLessonNameQuery"></el-input>
    </div>
    <el-table :data="filterData()" border class="table" header-cell-class-name="table-header">
      <el-table-column prop="displayOrder" label="Week"
        :width="inMiniScreen ? (this.$widthRatio * 45) : (this.$widthRatio * 80)">
        <template #default="scope">
          {{ scope.row.displayOrder }}
        </template>
      </el-table-column>
      <el-table-column v-if="!inMiniScreen" prop="lessonName" label="Lesson Name">
        <template #default="scope">
          <el-tag>{{ scope.row.lessonName }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Operation" :width="inMiniScreen ? 'auto' : this.$widthRatio * 160" align="center">
        <template #default="scope">
          <a :style="`display:${!inMiniScreen ? 'block' : 'inline'}; margin-right:10px;`" v-if="showAnswerLink &&
        scope.row.answerLink &&
        scope.row.answerLink.indexOf('https://') >= 0
        " target="_blank" title="Click here to view homework answer for the lesson" :href="scope.row.answerLink">
            {{ !inMiniScreen ? "Homework Answer" : "HA" }}
          </a>
          <a v-if="showAnswerLink && scope.row.linkForExtraFoundationExercise"
            :style="`display:${!inMiniScreen ? 'block' : 'inline'}; margin-right:10px;`"
            title="Click to view Extra Foundation Exercise" target="_blank"
            :href="scope.row.linkForExtraFoundationExercise">
            {{ !inMiniScreen ? "Extra Foundation" : "EF" }}
          </a>
          <a v-if="showAnswerLink && scope.row.linkForExtraDevelopmentExercise"
            :style="`display:${!inMiniScreen ? 'block' : 'inline'}; margin-right:10px;`"
            title="Click to view Extra Development Exercise" target="_blank"
            :href="scope.row.linkForExtraDevelopmentExercise">
            {{ !inMiniScreen ? "Extra Development" : "ED" }}
          </a>
          <a v-if="showAnswerLink && scope.row.linkForExtraEnrichmentExercise"
            :style="`display:${!inMiniScreen ? 'block' : 'inline'}; margin-right:10px;`"
            title="Click to view Extra Enrichment Exercise" target="_blank"
            :href="scope.row.linkForExtraEnrichmentExercise">
            {{ !inMiniScreen ? "Extra Enrichment" : "EE" }}
          </a>
          <a title="Review & Discuss" href="javascript:void(0)" @click="openForum(scope.row)">
            {{ !inMiniScreen ? "Forum" : "💬" }}
          </a>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getData } from "../../service/api";

export default {
  props: {
    classRoom: Object,
    roomId: String,
  },
  data() {
    return {
      controllerUrl: "/classRoom",
      query: {
        roomId: null,
        lessonName: null,
      },
      showAnswerLink: false,
      tableData: [],
      purchasedRecords: [],
    };
  },
  watch: {
    roomId(newValue) {
      if (newValue) {
        if (newValue !== this.query.roomId) {
          this.initAndLoadData(newValue);
        }
      }
    },
  },
  created() {
    this.initAndLoadData(this.roomId);
  },
  computed: {
    inMiniScreen() {
      return this.$store.state.inMiniScreen;
    },
  },
  methods: {
    initAndLoadData(roomId) {
      this.query.roomId = roomId;
      this.showAnswerLink = false;
      if (this.classRoom.activeStatus === 2048) {
        this.showAnswerLink = true;
      }

      this.loadData();
      // this.loadPurchasedRecords();
    },
    loadData() {
      getData(`${this.controllerUrl}/getAllLessonsInClassRoom/${this.query.roomId}`).then(
        (res) => {
          let resData = res.result;
          this.tableData = resData.list;
        }
      );
    },
    loadPurchasedRecords() {
      getData(`/student/getAllPurchaseRecords`, {
        studentId: this.$user.getUsername(),
        objectType: "Lesson",
        startExpiryTime: new Date(),
      }, {
        isShowLoading: false,
      }).then((res) => {
        if (res.result && res.code === "200") {
          this.purchasedRecords = res.result;
        } else {
          this.$message.error(
            "Fetch purchased records failed, error message: " + res.message
          );
        }
      });

    },
    getPurchasedRecordsByLessonId(lesson) {
      return this.purchasedRecords.filter(e => e.objectId === lesson.lessonId && (e.itemId === "ExtraFoundationExercise" || e.itemId === "ExtraDevelopmentExercise" || e.itemId === "ExtraEnrichmentExercise"));
    },
    getPurchasedItemShortName(purchasedItem) {
      if (purchasedItem.itemId === "ExtraFoundationExercise") {
        return "EF";
      }
      if (purchasedItem.itemId === "ExtraDevelopmentExercise") {
        return "ED";
      }
      if (purchasedItem.itemId === "ExtraEnrichmentExercise") {
        return "EE";
      }
    },
    getPurcahsedResourceUrl(purchasedItem) {
      let lesson = this.tableData.find(e => e.lessonId === purchasedItem.objectId)
      return lesson ? lesson[`linkFor${purchasedItem.itemId}`] : "javascript:void(0)";
    },
    filterData() {
      let recordList = this.tableData;
      if (this.query.lessonName) {
        recordList = recordList.filter(
          (e) => e.lessonName.indexOf(this.query.lessonName) >= 0
        );
      }

      return recordList;
    },
    handleLessonNameQuery(val) {
      console.log("handleLessonNameQuery", val);
      this.query.lessonName = val;
    },
    openForum(lesson) {
      this.$emitter.emit("OPEN_FORUM", [lesson.lessonId]);
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 1.17rem;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
